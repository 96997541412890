.oliveacaademy{
    background-color: rgb(201, 230, 255);
}



.btn-grad54 {
    background-image: linear-gradient(to right, #2500a0 0%, #54b0ea  51%, #109f9c  100%);
    margin: 0px;
    padding: 7px 20px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 12px;
    display: block;
  }
  
  .btn-grad54:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }