.colllege{
    background-color: rgb(0, 6, 185);
}

.exofficiocard {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 2px 1px 0 rgba(0,0,0,0.2);
    transition: 0.1s;
  }
  
  /* On mouse-over, add a deeper shadow */
  .exofficiocard:hover {
    box-shadow: 0 4px 32px 0 rgba(0,0,0,0.2);
  }

  .buttonlink{
    background-color: #1489d1; /* Green */
    border: none;
    color: white;
    padding: 7px 66px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 11px;
  }

  .btn-grad14 {
    background-image: linear-gradient(to right, #2500a0 0%, #54b0ea  51%, #109f9c  100%);
    margin: 0px;
    padding: 7px 20px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 12px;
    display: block;
  }
  
  .btn-grad14:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }


  .blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }